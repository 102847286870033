.location-item {
  margin-right: 3px;

  &:before {
    content: "";
    vertical-align: text-bottom;
  }

  & + & {
    &:before {
      content: "|";
      padding-right: 2px;
    }
  }
}