@import "../../variables.scss";
@import "../../mixins.scss";

.user-info-birthday  {
  @media (max-width: $media-575) {
    flex-wrap: wrap;
  }

  .react-dalder-select {
    width: calc(33% - 10px);

    @media (max-width: $media-575) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.location-select {
  .react-dalder-select:not(:last-child) {
    margin-bottom: 10px;
  }
}

.period-container-wrapper {
  display: flex;

  .react-dalder-select {
    width: calc(50% - 10px);
  }
}

.month-year-period-wrapper {
  padding-left: 5px;
  width: 100%;

  .month-year-period {
    width: calc(50% - 5px );

    .react-dalder-select {
      width: calc(50% - 10px);

      &:first-child{
        margin-left: 5px;
        width: calc(50% - 5px);
      }
    }
  }

  .liner-month-year-period {
    margin-left: 5px;
    line-height: 26px;
  }
}

/////////////////////////////////////////////

.react-dalder-select {
  position: relative;
  margin-left: 10px;
  border: none;

  .select-button {
    position: relative;
    white-space: nowrap;
    width: 100%;
    border: 1px solid $secondBorderColor;
    height: 30px;
    text-overflow: ellipsis;
    background: $grey8 url(/resource/img/icons/arrows.svg) no-repeat 130px;
    background-position: calc(100% - 4px);
    background-size: 12px auto;
    text-align: left;
    padding: 0 8px;

    &:hover:not([disabled]){
      border: 1px solid $activeContainer;
      color: $activeContainer;
      background: $grey8 url(/resource/img/icons/arrows-active.svg) no-repeat 130px;
      background-position: calc(100% - 4px);
      background-size: 12px auto;
    }

    &[disabled] {
      cursor: not-allowed;
      color: $grey4;
      opacity: .65;
    }

    .value-container {
      display: inline-block;
      width: calc(100% - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
      @include setFontRegular(14px, 18px);
      vertical-align: middle;
    }

    .clear-value-btn {
      position: absolute;
      background-position: 50% 50%;
      background: transparent url(/resource/img/icons/close-select-icon.svg) no-repeat;
      background-size: 12px auto;
      top: 9px;
      right: 18px;
      width: 12px;
      height: 12px;

      &:before {
        content: none;
      }
    }
  }

  &.open .select-button {
    &,
    &:hover {
      background: $grey8 url(/resource/img/icons/arrows-up-active.svg) no-repeat 130px;
      background-size: 12px auto;
      background-position: calc(100% - 4px);
    }
  }

  .select-values-container {
    position: absolute;
    background: $bgShareColor;
    border: 1px solid $inputBorderColor;

    z-index: 1;
    left: 0;
    right: 0;

    &.top {
      bottom: 100%;
    }

    &.bottom {
      top: 100%;
    }

    .search-input-container {
      height: 30px; // constant SEARCH_INPUT_HEIGHT in SelectOptionsList must have same value

      input {
        width: 100%;
      }
    }

    .items-container {
      list-style: none;
      padding: 0;
      position: relative;
      overflow: auto;
      margin-bottom: 0;

      .select-option {
        min-height: 30px;
        border-bottom: 1px solid $inputBorderColor;
        padding: 5px 6px;
        cursor: pointer;
        @include setFontRegular(14px, 18px);
      }
    }
  }
}