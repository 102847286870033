@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.field-logical-container {
  margin-bottom: 15px;
  border-bottom: 1px solid $inputBorderColor;

  &.border-none {
    border: none;
  }
}