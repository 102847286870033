@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.known-language-level, .known-language-level-title {
  width: calc(100% - 20px);

  .react-dalder-select, .language-level-title {
    width: 30%;
  }
  .language-level-title {
    text-align: center;
    margin-left: 10px;
  }

  .known-language-remove {
    margin-top: 0;
  }
}