@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.file-name, .btn-file {
  width: 100%;
  padding: 6px 12px;
  overflow: hidden;
  height: 30px;
  margin-left: 10px;
}

.file-name {
  border: 1px solid $secondBorderColor;
  padding: 6px 8px;
}

.btn-file {
  @include setFontRegular(12px, 18px);
}

.image-cv-container {
  margin: 20px 0 0 10px;
  position: relative;
  width: fit-content;

  .cv-photo {
    width: 50px;
    height: 50px;
  }

  .image-delete {
    display: contents;
  }

  .image-delete-icon {
    width: 18px;
    height: 18px;
    right: -9px;
    top: -8px;
    position: absolute;
  }
}