@import "../../../../variables.scss";
@import "../../../../mixins.scss";


.location-info-message, .location-item-container {
  margin-left: 10px;
}

.location-item {
  @include setFontRegular(13px, 16px);
  height: fit-content;
}

.remove-location-btn {
  border: none;
  background: transparent;
  padding: 0 0 0 2px;

  .remove-location-icon {
    transform: rotate(45deg);
    width: 15px;
  }
}