@import "../../../../common.scss";

.help-button {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .help-icon {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $blue;
    display: inline-block;

    color: $blue;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
  }

  .text-block {
    display: none;
    position: absolute;
    z-index: 2;
    right: -217px;
    top: -17px;
    background: $bgShareColor;
    width: 200px;
    padding: 12px;
    border: 1px solid $blue;
    color: $mainTextColor;
    @include setFontRegular(12px, 19px);

    @media (max-width: $media-992) {
      right: -17px;
      top: 29px;
    }

    &:before {
      display: block;
      content: ' ';
      position: absolute;
      left: -10px;
      top: 19px;
      background: url("/resource/img/triangle.png");
      width: 10px;
      height: 12px;

      @media (max-width: $media-992) {
        left: 168px;
        top: -11px;
        transform: rotate(90deg);
      }
    }
  }

  &:hover .text-block {
    display: block;
  }
}