@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.mobile-container {
  .field-block {
    flex-direction: column;
    position: relative;
  }
  .field-block .control-label {
    text-align: left;
  }
  .field-container {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    .input-container-wrapper:not(:first-child) {
      margin-left: 10px;
    }
    .form-control, .react-dalder-select:first-child, .input-container:first-child {
      margin-left: 0
    }
    .form-control {
      width: 100%
    }
    .own-car-question {
      margin-left: 0;
    }
  }

  .help-button .help-icon {
    position: absolute;
    top: 0;
    right: 15px
  }
  .location-select {
    display: flex;
    flex-direction: row;
  }
  .location-select .react-dalder-select, .user-info-birthday .react-dalder-select, .input-container-wrapper  {
    flex: 1 1 0;
  }
  .file-name  {
    margin-left: 0;
  }
  .location-info-message, .location-item-container {
    margin-left: 0;
  }
}

.field-block {
  padding: 0 15px 0 15px;
  margin-bottom: 20px;
  display: flex;

  &.form-group.required {
    .control-label {
      &:after {
        position: absolute;
        top: 8px;
        font-size: 22px;
      }
    }
  }

  .control-label {
    position: relative;
    width: 255px;
    text-align: right;
    @include setFontRegular(14px, 16px);
    color: $mainTextColor;
    padding: 8px 20px 0 0;

    .bold-label {
      @include setFontBold(14px, 22px);
    }
  }

  &.other-speciality {
    margin-top: -10px;
  }

  &.form-delimiter-field-block {
    .control-label {
      padding-top: 0;
    }

    .form-delimiter {
      margin: 10px 0 0 10px;
    }
  }
}