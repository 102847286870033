@import "mixins";
@import "variables";


.modal-lg {
    @media (min-width: 992px) {
        width: $contentWidth;
    }
}

.v-align-outer {
    display: table;

    .v-align-inner {
        display: table-cell;
        vertical-align: middle;
    }
}

@for $i from 1 through 4 {
    .columns-count-#{$i} {
        @include setColumns($i);
    }
}
