$black: #262626;
$black1: #000000;
$black2: #353535;
$white: #ffffff;
$blue: #1494d1;// old one #2bb8f6 then 5eafd1
$blue1:#1494d1; // old one #2ab7f5
$blue2: #4867aa;
$blue3: #1da1f2;
$blue4: #45c1f7;
$blue5: #1b94e8;
$blue6: #1494d1;
$blue7: #082B3B;
$yellow: #ffc223;
$orange: #ff6600;
$red: #f34a38;
$red-offline: #ff0000;
$red-hasError: #a94442;
$light-red: #d09c9b;
$green-hasSuccess: #3c763d;
$green1: #7ECA44;
$yellow-hasWarning: #fcca60;
$mainTextColor: #444444;
$secondTextColor: #a9a9a9;
$dangerColor: #e36960;
$borderColor: #ececec;
$borderColor1: #dda001;
$borderColor2: #759e3d;
$secondBorderColor: #d6d6d6; // old #d6d6d6 change back from 999189
$thirdBorderColor: #e9e9e9; // new
$grey: #a2a2a2;
$grey-text: #333;
$grey-footer-header: #082b3b;
$grey1: #404040;
$grey2: #6f6f6f; // old #cfcfcf
$grey3: #414141;
$grey4: #959595;
$grey5: #888888;
$grey6: #cecece;
$grey7: #e9e9e9;
$grey8: #f7f7f7;
$grey9: #f5f5f5;
$grey10: #aeaeae;
$grey11: #4b4b4b;
$grey12: #575757;
$grey13: #adadad;
$grey14: #d8d8d8;
$grey15: #808080;
$grey16: #919191;
$grey17: #dddddd;
$greyTable: #707070;
$gray-progress: #DAD8D9;
$mob-modal-bg1: #272727;
$mob-modal-bg2: #3a3a3a;
$categoryTitleTextColor: #333333;
$dropDownTextColor: #626262;
$inputBorderColor: #e3e3e3;
$navBgColor: $grey-footer-header;
$userStatusOnlineColor: #33e021;
$userStatusOfflineColor: $grey6;
$label-color: #313131;// old $mainTextColor
$requireColor: #ffaa14;// old #e80000
$lightTextColor: #818181;
$iconsTextColor: #999999;
$linkColor: #1494d1;
$fbColor: #3c5a99;
$twColor: #439cd6;
$duColor: #f26722;
$gpColor: #da4b42;
$bgColor: #85b83f;
$bgShareColor: #ffffff;
$bgShareHoverColor: #e5e5e5;
$contentWidth: 1180px;
$shareButtonWidth: 40px;
$shareButtonHeight: 37px;
$selectHeight: 33px;
$selectContainerBg: white;
$activeContainer: #1494d1;
$bgInfoBannerBlockBackgroundWhite: $bgShareColor;
$bgInfoBannerBlockBackgroundBlue: #92deeb;
$bgInfoBannerBlockBackgroundGrey: #474f59;
$button-verified: #34d423;
$profile-line: rgba(0, 0, 0, .46);
$empty-item-color: #b6b6b6;
$transparent: transparent;
$borderAvailableShipping: #EBEBEB;
$deliveryPrice: #BDBDBD;
$omnivaColor: #EA5B08;

$gradient-top: #11224D;
$gradient-bottom: #60A2D2;

$bgLight: #f0eeee;

$imageUploadBgColor :#F7FCFF;
$imageUploadingBgColor :#F7FCFF;
$imageUploadingBgColorSuccess :#E3F4FE;
$imageUploadingBorder :#D7D8DA;
$imageUploadingBorderSuccess :#5E8DAB;
$imageUploadingHasImageBgColor :#EDF8FE;

$UploadBgColor :#EAF8FF;
$UploadImageItemBgColor :#DFF5FF;
$UploadImageItemImgBorderColor :#C4E6F6;

$transparent-white-bg: #ffffffb8;

$orderStatusInProgress: #34d423;
// styles for mobile version site
$f-link: #1b94e8;
$f-text: #929292;
$gray-border: #d5d5d5;
$gray-border2: #d8d8d8;
$gray-background: #f1f1f1;
$gray-text: #c5c5c5;
$gray-text-2: #d6d6d6;
$gray-mid: #8d8d8d;
$gray-transparent: #8d8d8d2e;
$gray-text-3: #716f6f;
$textColor: #716f6f;
$gray-border-color: #ccc;
$blue-text: #4bb9eb;
$gray-icon: #c5c5c5;


//@media
$media-920: 920px;
$media-992: 992px;
$media-1080: 1080px;
$media-1200: 1200px;
$media-1780: 1780px;
$media-1880: 1880px;
$media-1980: 1980px;
$media-2080: 2080px;
$media-2280: 2280px;
$media-2580: 2580px;
$media-sm-768: 768px;
$media-575: 575px;
$media-450: 450px;
$media-375: 375px;


// border-radius
$box-border: 20px;
$small-box-border: 15px;
$mini-box-border: 10px;
$button-border: 8px;
$input-border: 8px;
$section-border-radius: 8px;

$infoColor: #1494d1;
$secondColor: #FFAA14;
$thirdColor: #FFF6E6;

$normal_box_shadow: 1px 2px 4px 0;

// New Variables for Stripe Person Cards
$cardBackground: #f2f2f2; // Light grey background for the card
$cardBorder: #d1d1d1; // Slightly darker grey for the border
$iconBackground: $blue; // Using the existing blue for the user icon
$dobColor: $black2; // Dark grey for Date of Birth
$badgeBackground: $blue1; // Using existing blue1 for badges
$badgeTextColor: $white; // Using existing white for badge text