@import "../../../variables.scss";
@import "../../../mixins.scss";

.field-container {
  width: 300px;

  .input-container {
    margin-left: 10px;

    &.radio-container, &.checkbox-container {
      margin-top: 8px;
    }

    input[type=checkbox] + label, input[type=radio] + label {
      @include setFontRegular(14px, 16px);
    }
  }

  .form-control {
    height: 30px;
    width: calc(100% - 10px);
    margin-left: 10px;
    border: 1px solid $secondBorderColor;
    box-shadow: none;
    padding: 6px 8px;

    &.textarea-input {
      height: auto;

      &::placeholder {
        @include setFontRegular(14px, 16px);
        color: $secondTextColor;
      }
    }
  }

  .years-period-block {
    .form-control {
      width: calc(50% - 10px);
    }

    .form-control:last-child {
      margin-left: 5px;
    }

    .liner-years-period {
      margin-left: 5px;
      line-height: 26px;
    }
  }

  .action-item-btn {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    margin-top: 10px;
    @include setFontRegular(14px, 18px);
    color: $mainTextColor;

    .action-item-icon {
      width: 17px;
      margin-right: 5px;

      &.remove-item-icon {
        transform: rotate(45deg);
      }
    }
  }
}