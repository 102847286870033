@import "../variables.scss";
@import "../mixins.scss";

.cv-container {
  margin-top: 40px;
  position: relative;

  @media (max-width: $media-sm-768) {
    margin-top: 60px;
  }

  .d-flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .panel {
    &.form-section {
      border-bottom: 1px solid $secondBorderColor;
    }
  }

  .control-buttons-container {
    position: absolute;
    right: 0;
    top: -70px;

    @media (max-width: $media-sm-768) {
      position: relative;
      float: right;
      top: -50px;
    }

    .btn-cv-top {
      @include setFontRegular(14px, 16px);
      border: none;
      background: transparent;
      padding: 10px;
      margin-left: 10px;
      color: $mainTextColor;

      @media (max-width: $media-375) {
        padding: 10px 5px;
        margin-left: 5px;
      }

      &:hover, &:focus {
        color: $blue;
      }

      .cv-top-btn-icon {
        padding-left: 10px;
        width: 28px;
      }
    }
  }

  .bottom-cv-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px auto 0;

    @media (max-width: $media-sm-768) {
      margin: 10px auto 0;
    }

    .btn-cv {
      margin: 0 10px;
      min-width: 135px;

      @media (max-width: $media-sm-768) {
        margin: 10px;
      }
    }
  }
}
