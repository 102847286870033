@mixin text-dashed($lineWidth: 2px, $lineColor: #e34b4e) {
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - #{$lineWidth/2});
    height: 0;
    border-top: $lineWidth solid $lineColor;
  }
}

@mixin mobileButtonStyle($color, $border-radius: 12px) {
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  color: white;
  border-radius: $border-radius;
  margin-top: 10px;
  background-color: $color;
  @include setFontBold(14px, 14px);
  text-transform: uppercase;
}

@mixin setFontSemiBold($size, $lineHeight: inherit) {
  font-family: open-sans-semibold, sans-serif;
  font-size: $size;
  font-weight: normal;
  line-height: $lineHeight;
}

;

@mixin setFontBold($size, $lineHeight: inherit) {
  font-family: open-sans-bold, sans-serif;
  font-size: $size;
  font-weight: normal;
  line-height: $lineHeight;
}

;

@mixin setFontRegular($fontSize, $lineHeight: inherit) {
  font: #{$fontSize}/#{$lineHeight} "open-sans-regular", sans-serif;
  font-weight: normal;
}

@mixin setFontOpenSans($fontSize, $lineHeight: inherit) {
  font: #{$fontSize}/#{$lineHeight} "open-sans", sans-serif;
  font-weight: normal;
}

@mixin setFontItalic($fontSize, $lineHeight) {
  font: #{$fontSize}/#{$lineHeight} "open-sans-italic", sans-serif;
  font-weight: normal;
}

@mixin setFontMerriweatherBold($fontSize, $lineHeight) {
  font: #{$fontSize}/#{$lineHeight} "merriweather-bold", sans-serif;
  font-weight: normal;
}

@mixin setFontMerriweatherRegular($fontSize, $lineHeight) {
  font: #{$fontSize}/#{$lineHeight} "merriweather-regular", sans-serif;
  font-weight: normal;
}

@mixin setFontMerriweatherLight($fontSize, $lineHeight) {
  font: #{$fontSize}/#{$lineHeight} "merriweather-light", sans-serif;
  font-weight: normal;
}

@mixin onlineStatusMark($diameter: 7px, $margin: 0 8px 1px 0, $onlineColor: #33e021, $offlineColor: red) {
  &:before {
    content: ' ';
    display: inline-block;
    width: $diameter;
    height: $diameter;
    border-radius: 50%;
    background-color: $offlineColor;
    margin: $margin;
  }
  &.online:before {
    background-color: $onlineColor;
  }
}

@mixin switchCheckBoxStyle() {
  .before-label, .after-label {
    font-weight: bold;
  }

  .switch-style-checkbox {
    display: none;
  }

  .switch-style-checkbox-label {
    &::before {
      content: none !important;
    }
  }

  .switch-style-checkbox:checked + .switch-style-checkbox-label::after {
    left: 20px;
    cursor: pointer;
  }

  .switch-style-checkbox:checked + .switch-style-checkbox-label {
    background-color: $blue;
  }

  .switch-style-checkbox-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background-color: $grey;
    border-radius: 20px;

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      top: 1px; // TO GIVE AN EFFECT OF CIRCLE INSIDE SWITCH.
      left: 1px;
      transition: all 0.3s;
    }
  }

  .switch-style-checkbox-container {
    display: flex;
    gap: 4px;
  }
}

@mixin userRating($starColor, $countColor) {
  display: flex;

  .static-rating {
    display: block;
    margin: 0;

    .star {
      color: $starColor;
      margin-right: 1px;
    }
  }

  .user-count {
    color: $countColor;
    margin-left: 5px;
    display: flex;

    .user-count-icon {
      position: relative;
      padding-left: 18px;
      margin: 0 5px;

      &:before {
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 11px;
      }

      .number {
        padding: 1px 0 0;
      }
    }
  }
}

@mixin setPlaceholderColor($color) {
  &::-webkit-input-placeholder {
    opacity: 1;
    color: $color;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: $color;
  }
  /* Firefox 19+ */
  &:-moz-placeholder {
    opacity: 1;
    color: $color;
  }
  /* Firefox 18- */
  &:-ms-input-placeholder {
    opacity: 1;
    color: $color;
  }
}

@mixin setPlaceholderFont($size, $lineHeight, $fontFamily: arial, $fontStyle: italic, $vAlign: middle) {
  &::-webkit-input-placeholder {
    font-family: $fontFamily;
    font-style: $fontStyle;
    font-size: $size;
    line-height: $lineHeight;
    vertical-align: $vAlign;
  }
  &::-moz-placeholder {
    font-family: $fontFamily;
    font-style: $fontStyle;
    font-size: $size;
    line-height: $lineHeight;
    vertical-align: $vAlign;
  }
  /* Firefox 19+ */
  &:-moz-placeholder {
    font-family: $fontFamily;
    font-style: $fontStyle;
    font-size: $size;
    line-height: $lineHeight;
    vertical-align: $vAlign;
  }
  /* Firefox 18- */
  &:-ms-input-placeholder {
    font-family: $fontFamily;
    font-style: $fontStyle;
    font-size: $size;
    line-height: $lineHeight;
    vertical-align: $vAlign;
  }
}

@mixin selectricStyles($inputHeight, $inputWidth, $inputBackground, $borderColor, $borderWidth) {
  border: $borderWidth solid $borderColor;
  border-radius: 0;
  height: $inputHeight;
  &, .selectric {
    width: $inputWidth;
  }
  .label {
    background: $inputBackground;
  }

  $innerHeight: $inputHeight - ($borderWidth * 2);
  .selectric {
    height: $innerHeight;

    .label, .button {
      height: $innerHeight - ($borderWidth * 2);
      line-height: $innerHeight - ($borderWidth * 2);
    }
  }
  .button, .button:hover {
    &:before {
      top: $innerHeight/2 - 6px;
    }
  }
}

@mixin setColumns($count) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;
}

@mixin flex($direction, $wrap) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: $direction;
  flex-wrap: $wrap;

  -webkit-flex-flow: $direction $wrap;
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 #2ab7f5;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);

  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.yii-debug-toolbar {
  display: none;
}
