.change-photo-dialog {
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    height: 20px;
    width: 20px;
    padding: 0;
    border: none;
  }

  .btn-cv-photo-confirm {
    margin: 20px auto 10px;
    border: none;
    min-width: 100px;
  }

  .modal-body {
    text-align: center;
    padding-top: 40px;
  }
}