@import "../../../../variables.scss";
@import "../../../../mixins.scss";


.user-info-children {
  position: relative;

  .select-children {
    position: absolute;
    width: 30%;

    .react-dalder-select {
      width: 100%;
      float: left;
      display: inline;
    }
  }

  .select-children-age {
    display: flex;
    flex-wrap: wrap;

    .react-dalder-select {
      width: 30%;
    }

    .react-dalder-select:first-child {
      margin-left: calc(30% + 20px);
      margin-bottom: 10px;
    }
  }
}